import * as pms  from "./PriceModeSettings";

export module MPC_PricemodeSwitchingModule {
    import MpcPricemodeSettingModule = pms.MPC_PricemodeSettingModule;

    export abstract class PricemodeSwitchingListener {

        static initializePricemodeSwitchingListener(): void {
            if (!window.localStorage) {
                return;
            }
            if (window.shell) {
                window.shell.subscribeTo('PriceModeChanged', PricemodeSwitchingListener.onPriceModeChange.bind(this), undefined);
            }    
        }

        static onPriceModeChange(newMode: string): void {
            PricemodeSwitchingController.onPriceModeChange(newMode);
        }
    }

    export abstract class PricemodeSwitchingController {

        private static domNode: Element = document.documentElement;
        private static SessionStoragePriceModeField: string = "PriceMode";
        private static NetClass: string = "current-pricemode-net";
        private static GrossClass: string = "current-pricemode-gross";
        private static Net: string = "Net";
        private static Gross: string = "Gross";
        private static currentPriceMode: string = null;
        private static isNonLegacy: boolean = false;

        static initializePricemodeByHtmlClassSendByApplication(): void {
            if (!window.localStorage || !PricemodeSwitchingController.domNode) {
                return;
            }

            const scope: HTMLElement = PricemodeSwitchingController.domNode.querySelector('.mpc-scope');
            let grid = null;

            if(scope)
                grid = scope.querySelector('.grid') as HTMLElement;

            if (grid && grid.dataset.portalmandanttoken && grid.dataset.portalmandanttoken === "GLB") {
                PricemodeSwitchingController.isNonLegacy = true;
                PricemodeSwitchingController.onPriceModeChange(PricemodeSwitchingController.Net);
            }
            else {
                if (PricemodeSwitchingController.domNode.classList.contains(PricemodeSwitchingController.NetClass)) {
                    PricemodeSwitchingController.onPriceModeChange(PricemodeSwitchingController.Net);
                } else if (PricemodeSwitchingController.domNode.classList.contains(PricemodeSwitchingController.GrossClass)) {
                    PricemodeSwitchingController.onPriceModeChange(PricemodeSwitchingController.Gross);
                } else {
                    // this happens on MPC content service pages
                    PricemodeSwitchingController.isNonLegacy = true;
                    let priceModeFromSessionStorage = PricemodeSwitchingController.fetchPriceMode();
                    PricemodeSwitchingController.onPriceModeChange(priceModeFromSessionStorage);
                }
            }
        }

        static onPriceModeChange(newMode: string): void {
            if (PricemodeSwitchingController.currentPriceMode === newMode) {
                return;
            }
            PricemodeSwitchingController.currentPriceMode = newMode;
            PricemodeSwitchingController.persistPriceMode(newMode);
            if (PricemodeSwitchingController.isNonLegacy) {

                if (PricemodeSwitchingController.currentPriceMode === PricemodeSwitchingController.Net)
                    PricemodeSwitchingController.domNode.classList.add(PricemodeSwitchingController.NetClass);
                else
                    PricemodeSwitchingController.domNode.classList.add(PricemodeSwitchingController.GrossClass);
            }

            // execute:
            if (window.shell) {
                window.shell.publishTo("ESPP1.ManualProductComparison.PriceModeClassAdded", null);
            }    
        }
        

        static fetchPriceMode(): string {
            let item = window.localStorage.getItem(PricemodeSwitchingController.SessionStoragePriceModeField);
            if (item === null) {
                item = MpcPricemodeSettingModule.espp_mpc_default_pricemode; // default price mode
            }

            return item;
        }

        static persistPriceMode(mode: string): void {
            window.localStorage.setItem(PricemodeSwitchingController.SessionStoragePriceModeField, mode);
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    MPC_PricemodeSwitchingModule.PricemodeSwitchingListener.initializePricemodeSwitchingListener();
    MPC_PricemodeSwitchingModule.PricemodeSwitchingController.initializePricemodeByHtmlClassSendByApplication();
});